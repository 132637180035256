<template>
  <section class="listar-corretor pr-4">
    <div class="m-1 mr-4 px-0">
      <breadcrumb title="Corretores" actualPage="Lista de Corretores" />
    </div>

    <div
      class="
        flex
        mt-8
        mb-10
        gap-3
        w-full
        md:w-1/3
        lg:w-1/3
        lp:w-1/3
      "
    >
      <Exportar
        v-if="perfilHelpers.isInsurance()"
        :Type="2"
        :Label="'Corretores'"
      />

      <router-link to="/corretores/cadastrar-novo-corretor">
        <vs-button
          v-if="permissoes && podeCadastrarNovoCorretor">
          Cadastrar novo corretor
        </vs-button>
      </router-link>

    </div>
    <div class="table-content">
      <vs-table
        :sst="true"
        @search="handleSearch"
        @change-page="handleChangePage"
        search
        class="table-list"
        noDataText="Nenhum corretor encontrado"
        :data="listaCorretores || []"
        :hoverFlat="true"
      >
        <template slot="thead">
          <vs-th></vs-th>
          <vs-th class="text-center">CNPJ</vs-th>
          <vs-th class="text-center">Nome</vs-th>
          <vs-th class="text-center">Comissão</vs-th>
          <vs-th class="text-center">Data de cadastro</vs-th>
          <vs-th class="text-center">Status SUSEP</vs-th>
          <vs-th class="text-center">Bloqueado</vs-th>
          <vs-th class="text-center">Data bloqueio</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr
            :data="tr"
            :state="data[indextr].IsActive ? null : 'danger'"
            :key="indextr"
            v-for="(tr, indextr) in data"
          >
            <vs-td :data="data[indextr].BrokerId" class="text-center">
              <vs-dropdown
                vs-custom-content
                vs-trigger-click
                class="cursor-pointer"
              >
                <vs-button color="dark" type="flat" title="Ações">
                  <i class="onpoint-dots-three-vertical icon-dots-three-vertical" role="button"></i>
                </vs-button>
                <vs-dropdown-menu style="width: 200px">
                  <vs-dropdown-item @click="goToDetalheCorretor(data[indextr])">
                    <p class="text-base px-6 py-2">
                      <span>Abrir cadastro</span>
                    </p>
                  </vs-dropdown-item>
                  <vs-dropdown-item
                    @click="
                      openModalBlock(
                        data[indextr].BrokerId,
                        data[indextr].IsActive
                      )
                    "
                  >
                    <p class="text-base px-6 py-2">
                      <span>{{
                        data[indextr].IsActive ? "Bloquear" : "Desbloquear"
                      }}</span>
                    </p>
                  </vs-dropdown-item>
                  <!-- <vs-dropdown-item divider>
                        <p class="text-base px-12 py-2">
                          <span>Excluir Inativo</span>
                        </p>
                      </vs-dropdown-item> -->
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-td>

            <vs-td :data="data[indextr].BrokerDocument" class="text-center">
              <span class="font-semibold">{{
                data[indextr].BrokerDocument
              }}</span>
            </vs-td>

            <vs-td :data="data[indextr].BrokerName" class="text-center">
              <span class="font-semibold">{{ data[indextr].BrokerName }}</span>
            </vs-td>

            <vs-td
              :data="data[indextr].ComissionPercentage"
              class="text-center"
            >
              <span class="font-semibold"
                >{{ data[indextr].ComissionPercentage }}%</span
              >
            </vs-td>

            <vs-td
              :data="data[indextr].ValidityRegistration"
              class="text-center"
            >
              <span class="font-semibold">{{
                new Date(data[indextr].ValidityRegistration)
                  | dateFormat("DD/MM/YYYY")
              }}</span>
            </vs-td>

            <vs-td :data="data[indextr].SUSEPStatus" class="text-center">
              <span class="font-semibold">{{
                data[indextr].SUSEPStatus || "----"
              }}</span>
            </vs-td>

            <vs-td :data="data[indextr].IsActive" class="text-center">
              <span class="font-semibold">{{
                data[indextr].IsActive ? "Não" : "Sim"
              }}</span>
            </vs-td>

            <vs-td :data="data[indextr].BlockDate" class="text-center">
              <span class="font-semibold" v-if="data[indextr].BlockDate">
                {{ data[indextr].BlockDate | moment("DD/MM/YYYY") }}
              </span>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination class="mt-5" :total="numberOfPages" v-model="currentPage">
      </vs-pagination>
    </div>
    <vs-popup
      class="validacao-data-vigente-modal"
      :title="modalTitle"
      :active.sync="openModalBlockBroker"
    >
      <div class="vw-row mt-3">
        <div class="vx-col pt-5 w-full mt-2" v-if="brokerIsBlocked">
          <label class="font-semibold">Justificativa</label>
          <vs-textarea v-model="justifyBlock" />
        </div>
        <div class="vx-col w-full text-right">
          <vs-button @click="closeModalBlock()" class="button-default mr-5">
            Cancelar
          </vs-button>
          <vs-button @click="blockUnblockBroker()" class="button-primary">
            Confirmar
          </vs-button>
        </div>
      </div>
    </vs-popup>
  </section>
</template>

<script>
import _ from "lodash";
import axiosInstance from "@/axios";
import Breadcrumb from "@/layouts/components/breadcrumb/Breadcrumb.vue";
import { mapActions } from "vuex";
import Exportar from "@/components/exportar-dados/index.vue";
import PerfilHelpers from "@/helpers/PerfilHelpers";
import * as Permissoes from "@/utils/permissions.js"
import mapfreProvider from "@/providers/mapfre-provider.js";

export default {
  components: { Breadcrumb, Exportar },
  directives: {},
  data() {
    return {
      listaCorretores: [],
      totalItems: 0,
      currentPage: 1,
      rowsPerPage: 7,
      numberOfPages: 0,
      searchValue: "",
      modalTitle: "",
      brokerId: null,
      openModalBlockBroker: false,
      brokerIsBlocked: false,

      perfilHelpers: PerfilHelpers,
      permissoes: [],
    };
  },
  watch: {
    currentPage(newVal, oldVal) {
      this.listarCorretores();
    }
  },
  async mounted() {
    await this.$onpoint.loading(async () => {
      return await this.listarCorretores();
    });

    await this.$onpoint.loading(async () => {
      return await this.obterModuloPermissoes()
    });
  },
  created() {
    this.$appInsights.trackPageView({
      name: "corretores-listar",
      url: window.location.href
    });
  },
  computed: {
    podeCadastrarNovoCorretor() {
      return this.permissoes.includes("ExibirCadastrarNovo");
    },
  },
  methods: {
    ...mapActions("corretor-module", ["enableDisableBroker"]),
    goToDetalheCorretor(corretor) {
      this.$router.push({
        name: "detalhe-corretor",
        params: {
          corretorUniqueId: corretor.BrokerUniqueId
        }
      });
    },
    openModalBlock(brokerId, isBlocked) {
      this.modalTitle = !isBlocked
        ? "Deseja realmente desbloquear o corretor?"
        : "Deseja realmente bloquear o corretor?";
      this.openModalBlockBroker = true;
      this.brokerId = brokerId;
      this.brokerIsBlocked = isBlocked;
      this.justifyBlock = "";
    },
    closeModalBlock() {
      this.brokerId = null;
      this.openModalBlockBroker = false;
    },
    blockUnblockBroker() {
      if (!this.justifyBlock && this.brokerIsBlocked) {
        this.$onpoint.errorModal("Justificativa obrigatória");
        return;
      }
      this.$onpoint.loading(() => {
        const action = this.brokerIsBlocked ? "bloqueado" : "desbloqueado";
        return this.enableDisableBroker({
          id: this.brokerId,
          justify: this.justifyBlock
        })
          .then(response => {
            this.$onpoint.successModal(`Corretor ${action} com sucesso.`);
            this.openModalBlockBroker = false;
            this.listarCorretores();
          })
          .catch(err => this.$onpoint.errorModal(err.response.data.Errors));
      });
    },
    async listarCorretores() {
      await this.$onpoint.loading(async() => {
        return await axiosInstance
          .get(
            `api/Broker/GetSearchBrokers?searchValue=${this.searchValue}&currentPage=${this.currentPage}&rowsPerPage=${this.rowsPerPage}`
          )
          .then(response => {
            response = response.data.Response;
            this.listaCorretores = response.Data;
            this.totalItems = response.NumberOfRows;
            this.numberOfPages = response.NumberOfPages;
          })
          .catch(err => {
            this.$onpoint.errorModal(err.response.data.Errors);
          });
      });
    },

    handleSearch: _.debounce(function(searching) {
      const scope = this;

      function __search() {
        scope.searchValue = searching;
        scope.currentPage = 1;
        scope.listarCorretores();

        const pag1 = document.getElementsByClassName(
          "item-pagination vs-pagination--li"
        )[0];
        if (pag1) pag1.classList.add("is-current");
      }

      if (!searching) {
        if (this.searchValue) __search();
      } else {
        __search();
      }
    }, 750),

    handleChangePage(page) {
      this.currentPage = page;
      this.listarCorretores();
    },

    async obterModuloPermissoes() {
      this.permissoes = [];
      try {
        this.permissoes = await mapfreProvider.obterPermissoesModulo(Permissoes.Modulo.CORRETOR);
      } catch (error) {
        this.$onpoint.errorModal(error.response.data.Errors);
      }
    },
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/mixins";

.con-vs-popup {
  z-index: 52000;
}
.table-list {
  margin-top: -32px;

  ::v-deep .vs-table--thead {
    tr {
      background: transparent !important;
    }
  }

  ::v-deep .vs-table--td {
    padding: 25px 15px !important;
    margin-bottom: 1em;
  }

  ::v-deep .vs-table--search {
    max-width: initial;
    margin-bottom: 20px;
    i {
      font-size: 2em;
      margin-top: 30px;

      @include md {
        margin-top: -24px;
      }
    }

    input {
      padding-left: 40px !important;
      font-size: 1.1em !important;
      width: 300px;      
      height: 47px;
      margin-top: 30px;

      @include md {
        margin-top: -24px;
      }
    }
  }

  ::v-deep .vs-table-text {
    justify-content: center;
  }

  ::v-deep .tr-values {
    margin-bottom: 5px;
    border-left: solid 8px rgba(var(--vs-warning), 1);
    border-bottom: solid 10px #f8f8f8;
  }
}

.icon-dots-three-vertical::before{
  position: relative;
  top: 2px;
  font-size: 24px;
  color: #afafaf;
}
</style>
